import React, { Fragment } from "react"
import Sticky from "react-stickynode"
import { ThemeProvider } from "styled-components"
import { Modal } from "@redq/reuse-modal"
import { theme } from "common/theme/appModern"
import { ResetCSS } from "common/assets/css/style"
import GlobalStyle, {
  AppWrapper,
  ContentWrapper,
} from "containers/AppModern/appModern.style"
import Navbar from "containers/AppModern/Navbar"
import BannerSection from "containers/Agency/BannerSection"
import FeatureSection from "containers/Agency/FeatureSection"
// import AboutUsSection from 'containers/Agency/AboutUsSection';
import WorkHistory from "containers/Agency/WorkHistory"
import BlogSection from "containers/Agency/BlogSection"
// import TestimonialSection from 'containers/Agency/TestimonialSection';
// import TeamSection from 'containers/Agency/TeamSection';
import VideoSection from "containers/Agency/VideoSection"
import FaqSection from "containers/Agency/FaqSection"
import NewsletterSection from "containers/Agency/NewsletterSection"
import QualitySection from "containers/Agency/QualitySection"
import Footer from "containers/AppModern/Footer"
import { DrawerProvider } from "common/contexts/DrawerContext"
import "@redq/reuse-modal/es/index.css"
import Seo from "components/seo"

const Agency = () => {
  const lang = "en"
  return (
    <ThemeProvider theme={theme}>
      <Fragment>
        <Seo title="Dayra" />
        <Modal />
        <ResetCSS />
        <GlobalStyle />
        {/* End of agency head section */}
        {/* Start agency wrapper section */}
        <AppWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-active">
            <DrawerProvider>
              <Navbar lang={lang} />
            </DrawerProvider>
          </Sticky>
          <ContentWrapper>
            <BannerSection />
            {/* <FeatureSection /> */}
            {/* <AboutUsSection /> */}
            {/* <WorkHistory />
          <BlogSection /> */}
            <QualitySection />
            {/* <VideoSection />
          <TestimonialSection />
          <TeamSection />
          <FaqSection />
          <NewsletterSection /> */}
          </ContentWrapper>
          <Footer lang={lang} />
        </AppWrapper>
        {/* End of agency wrapper section */}
      </Fragment>
    </ThemeProvider>
  )
}
export default Agency
